import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QuotesProvider } from './QuotesContext';
import { MusicProvider } from './MusicContext';
import Header from './components/Header';
import Footer from './components/Footer';
import QuoteForm from './components/QuoteForm';
import QuoteList from './components/QuoteList';
import QuoteDetail from './components/QuoteDetail';
import MyQuotes from './components/MyQuotes';
import { ThirdwebProvider, ChainId, useAddress } from '@thirdweb-dev/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminQuoteList from './components/AdminQuoteList';
import GifComponent from './components/Wojak';
import GifComponent2 from './components/Pepe';
import MyReferrals from './components/MyReferrals';
import MyStats from './components/MyStats';
import Pfp from './components/Pfp';
import SignUp from './components/SignUp';
import AuthHandler from './components/AuthHandler';
import Ranking from './components/Ranking';
import Advertisement from './components/Advertisement';
import ProfitCalculator from './components/ProfitCalculator';
import GoogleAnalytics from './components/GoogleAnalytics';
import supabase from './supabaseClient';
import { useLocation } from 'react-router-dom';
import ComingSoon from './components/ComingSoon';

import img1 from './components/1.png';
import img2 from './components/2.png';

const isProduction = process.env.NODE_ENV === 'production';
const isComingSoon = false; // Cambiar a false para desactivar coming soon en production (isProduction on)

const activeChainId = ChainId.Mainnet;

const ProtectedRoute = ({ element: Component }) => {
  const address = useAddress();
  return address ? <Component /> : <Navigate to="/" />;
};

const AdminRoute = ({ element: Component }) => {
  const address = useAddress();
  return address ? <Component /> : <Navigate to="/" />;
};

const clientId = "45358a6e8539f50fffe1487854fb22a9";

const isInStandaloneMode = () => {
  return window.matchMedia('(display-mode: standalone)').matches || 
         window.navigator.standalone || 
         document.referrer.startsWith('android-app://');
};

const InnerApp = React.memo(() => {
  const address = useAddress();
  const [showGifs, setShowGifs] = useState(false);
  const [showWelcome, setShowWelcome] = useState(window.location.pathname === '/');
  const [blurBackground, setBlurBackground] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1600);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1750); 
  const [referralCode, setReferralCode] = useState(''); 
  const location = useLocation();
  const [isConnected, setIsConnected] = useState(false);

  const handleEnter = useCallback(() => {
    setShowGifs(true);
    setShowWelcome(false);
  }, [setShowGifs, setShowWelcome]);

  const resetWelcome = () => {
    setShowWelcome(false);
  };

  const handleWalletConnection = async (address) => {
    if (!address) {
      console.error('Error: Address is null or undefined');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('referrals')
        .select('referral_code')
        .eq('user_id', address);

      if (error) {
        throw error;
      }

      if (data && data.length === 1) {
        console.log("Referral code found:", data[0].referral_code);
        setReferralCode(data[0].referral_code); 
      } else if (data && data.length > 1) {
        console.error('Multiple referral codes found for user:', address);
      } else {
        const code = generateReferralCode();
        console.log("Generating new referral code:", code);
        const { data: newReferral, error: insertError } = await supabase
          .from('referrals')
          .insert([{ user_id: address, referral_code: code }]);

        if (insertError) {
          throw insertError;
        } else {
          console.log("New referral code created:", code);
          setReferralCode(code); 
        }
      }
    } catch (error) {
      console.error('Error fetching or creating referral code:', error.message);
    }
  };

  const generateReferralCode = () => {
    console.log("Generating referral code...");
    const randomPart = Math.random().toString(36).substring(2, 10);
    const newCode = 'mfc-' + randomPart;
    console.log("Generated referral code: ", newCode);
    return newCode;
  };

  useEffect(() => {
    if (address) {
      handleWalletConnection(address);
    }
  }, [address]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1800);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      const isHomePage = window.location.pathname === '/';
      setShowGifs(!isHomePage);
      setShowWelcome(isHomePage);
    };

    handleRouteChange();

    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  if (isComingSoon) {
    return <ComingSoon />;
  }

  return (
    <>
      <AuthHandler setShowWelcome={setShowWelcome} />
      
      {/* Renderiza el Header si no se muestra el welcome o si está en PWA */}
      {(isInStandaloneMode() || !showWelcome) && (
        <Header resetWelcome={resetWelcome} setBlurBackground={setBlurBackground} setIsConnected={setIsConnected}/>
      )}
      
      <div className={`main-content ${blurBackground ? 'blur-background' : ''}`}>
        <Routes>
          {isInStandaloneMode() ? (
            // Renderiza la ruta sin los props adicionales en PWA
            <Route path="/" element={<QuoteList isMobile={isMobile} />} />
          ) : (
            // Renderiza la ruta con todos los props adicionales cuando no está en PWA
            <Route path="/" element={<QuoteList onEnter={handleEnter} showWelcome={showWelcome} setShowWelcome={setShowWelcome} isMobile={isMobile} />} />
          )}
          <Route path="/submit-quote" element={<QuoteForm />} />
          <Route path="/my-quotes" element={<ProtectedRoute element={MyQuotes} />} />
          <Route path="/admin" element={<AdminRoute element={AdminQuoteList} />} />
          <Route path="/referrals" element={<MyReferrals referralCode={referralCode} />} /> 
          <Route path="/stats" element={<MyStats referralCode={referralCode} />} /> 
          <Route path="/pfp" element={<Pfp />} /> 
          <Route path="/signup" element={<SignUp />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/:slug" element={<QuoteDetail />} />
          <Route path="/advertisement" element={<Advertisement />} />
        </Routes>      
        {(location.pathname.match(/^\/mfc-\d+$/) || location.pathname === '/ranking' || location.pathname === '/pfp') && isLargeScreen && (
          <div>
            {showGifs && !isMobile && ( 
            <><div className="image-left">
                <img raggable="false" src={img2} alt="Left" />
              </div><div className="image-right">
                  <img raggable="false" src={img1} alt="Right" />
                </div></>
            )}
          </div>
        )} 
      </div>
      {(!showWelcome || isInStandaloneMode()) && (
        <Footer resetWelcome={resetWelcome} setBlurBackground={setBlurBackground} />
      )}
      <ToastContainer />
      {showGifs && !isMobile && ( 
        <div className={`gif-container`}>
          <GifComponent2 className={`${blurBackground ? 'blur-background' : ''}`} />
          <GifComponent className={`${blurBackground ? 'blur-background' : ''}`} />
        </div>
      )}
    </>
  );
});
  
function App() {
  return (
    <ThirdwebProvider desiredChainId={activeChainId} clientId={clientId}>
      <GoogleAnalytics />
      <QuotesProvider>
        <MusicProvider>
          <Router>
            <InnerApp />
          </Router>
        </MusicProvider>
      </QuotesProvider>
    </ThirdwebProvider>
  );
}

export default App;
